import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/index.scss'
// import AOS from 'aos'
// import 'aos/dist/aos.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueFullPage from 'vue-fullpage.js'
import './registerServiceWorker'

Vue.use(VueAwesomeSwiper)
Vue.use(VueFullPage)
Vue.config.productionTip = false
// Vue.prototype.aos = AOS

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
